import API from './api'
import { axiosWithErrorHandler } from './axios'
// import axios from 'axios'

export const GET_FILTER_REPORT_BY_ID = async (userId: string, startDate: string, endDate: string, page: number, limit: number) => {
    let urlFilterId = ""
    if(userId) {
        urlFilterId = `&username=${userId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_REPORT}?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;

};

export const GET_BET_DETAIL_BY_ID = async (userId: string, startDate: string, endDate: string, page: number, limit: number) => {
    let urlFilterId = ""
    if(userId) {
        urlFilterId = `&username=${userId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_BET_DETAIL}?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;

};


export const GET_BET_DETAIL_GAME_BY_ID = async (gameId: string, betId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_BET_DETAIL_GAME}?gameId=${gameId}&betId=${betId}`)
    return response.data;
};